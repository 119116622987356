.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: var(--gutter-md);
}

.name {
  font-weight: 500;
}

.mobileSecondaryRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--gutter-xs);
}
