.generateButton {
  width: 153px;
  min-height: 47px;
  font-size: 18px;
}

.regenerateButton {
  width: 172px;
  min-height: 47px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .mobileHeadlineTextArea {
    margin-top: 0;
    padding-top: 0;
  }

  .generateButton {
    width: 121px;
    min-height: 37px;
    font-size: 14px;
  }

  .regenerateButton {
    width: 136px;
    min-height: 37px;
    font-size: 14px;
  }
}