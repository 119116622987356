.wrapper {
  border: solid 1px var(--color-cadet-blue);
  background-color: var(--color-white);
  color: var(--color-oxfordBlue);
  width: 113px;
  height: 37px;
  margin: 16px;
}

.wrapper:hover {
  background-color: var(--color-athensGray-3) !important;
  color: var(--color-oxfordBlue) !important;
}