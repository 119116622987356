.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  font-size: 18px !important;
}

.wrapper .textarea {
  background: var(--color-white);
  border: 1px solid var(--color-mischka);
  font-size: 16px;
  padding: var(--gutter-lg);
  height: 280px;
}

.textarea + .submit {
  margin-top: var(--gutter-md);
}

.description {
  font-size: 16px;
  color: var(--color-dove-gray)
}

.submit {
  margin-left: auto;
}

.wrapper .copyButton {
  position: absolute;
  top: var(--gutter-lg);
  right: var(--gutter-md);
  z-index: 2;
}

.textarea.withCopyButton {
  padding-top: calc(2 * var(--gutter-lg));
}

.mobileWrapper {
  padding: calc(var(--gutter-smd) + var(--gutter-lg)) var(--gutter-md);
}

.mobileSubmit {
  margin-top: 0;
  margin-bottom: var(--gutter-sm);
}

.mobileHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


@media (max-width: 768px) {
  .wrapper .textarea {
    border: none;
    padding:var(--gutter-xs) var(--gutter-xs) 0;
  }
}

@media (max-width: 800px) {
  .wrapper .copyButton {
    width: 20px;
    height: 20px;
  }

  .wrapper .copyButton svg {
    width: 20px;
    height: 20px;
  }
}