.wrapper {
  display: flex;
  align-items: center;
  height: 36px;
  padding: var(--gutter-sm) var(--gutter-md);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-bunker);
  border: 1px solid var(--color-cadet-blue);
  background: var(--color-white);
  max-width: 656px;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}

.wrapper span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: var(--gutter-sm);
  flex-shrink: 0;
}

.wrapper .closeIcon {
  margin-left: var(--gutter-sm);
}

.wrapper.error {
  color: var(--color-bright-red);
  border-color: var(--color-bright-red);
}

.wrapper.error .icon svg {
  fill: var(--color-bright-red);
}

@media (max-width: 768px) {
  .wrapper {
   max-width: 90%;
  }
}