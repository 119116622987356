.wrapper {
  position: sticky;
  width: 100%;
  max-width: var(--main-width);
  top: calc(
    var(--main-header-height) +
    var(--audio-player-section-height) +
    var(--gutter-xl) +
    48px
  );
  margin: auto;
  z-index: 2;
  display: flex;
}


.cardWrapper {
  position: relative;
  min-height: auto;
  margin: 0;
  border-radius: 0;
  padding: 0;
  width: 100%;
}

.tabs {
  width: 100%;
}

.tabs :global(.ant-tabs-nav) {
  margin: 0 !important;
  width: 100%;
}

.tabs :global(.ant-tabs-nav-list) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: var(--gutter-xs);
}

.tabs :global(.ant-tabs-tab) {
  margin: 0 !important;
  display: flex;
  text-align: center;
}

.tabs :global(.ant-tabs-tab-btn:hover) {
  color: var(--color-bunker);
  font-size: 14px;
}

.tabs :global(.ant-tabs-tab-btn) {
  font-size: 14px;
  color: var(--color-cadet-blue);
}

.tabs :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: var(--color-bunker) !important;
}

.tabs :global(.ant-tabs-ink-bar) {
  display: none !important;
}

@media (min-width: 769px) {
  .wrapper {
    display: none;
  }
}

@media (max-width: 374px) {
  .tabs :global(.ant-tabs-tab-btn) {
    font-size: 12px;
    color: var(--color-cadet-blue);
    border-bottom: none !important;
  }

  .tabs :global(.ant-tabs-tab-btn:hover) {
    color: var(--color-bunker);
    font-size: 12px;
  }
}