.wrapper {
  font-weight: 500;
  font-size: 18px;
}

.wrapper .button {
  transition: none;
  margin-left: var(--gutter-sm);
  width: 103px;
  min-height: 40px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wrapper .button {
    width: 94px;
    min-height: 37px;
    font-size: 14px;
  }

  .button {
    margin-top: 12px;
  }
}