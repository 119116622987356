:root {
  /*Common*/
  --color-white: #fff;
  --color-black: #000;
  --color-bunker: #12151b;
  --color-midnight: #00112c;
  --color-athensGray: #f6f6f7;
  --color-athensGray-2: #e7eaee;
  --color-athensGray-3: #f4f6f8;
  --color-geyser: #d1d8e3;
  --color-alto: #d8d8d8;
  --color-silver: #cacaca;
  --color-pattenBlue: #d1e2ff;
  --color-mischka: #d3d8e2;
  --color-aluminium: #aab1bc;
  --color-cadet-blue: #a4afc2;
  --color-gray: #919191;
  --color-dove-gray: #6a6a6a;
  --color-hawkesBlue: #d5e0fc;
  --color-blue-bayoux: #576883;
  --color-shuttle-gray: #5b6e7f;
  --color-ceruleanBlue: #3358cc;
  --color-dodgerBlue: #4f93ff;
  --color-sail: #a8c8fb;
  --color-selago: #f2f7fe;
  --color-chathamsBlue: #12387a;
  --color-scienceBlue: #0056df;
  --color-mariner: #2867c9;
  --color-red: #ff0000;
  --color-guardsman-red: #d60000;
  --color-bright-red: #b60000;
  --color-bridesmaid: #feecec;
  --color-perfume: #d4b9fa;
  --color-yellowGreen: #d3e18a;
  --color-panache: #e5f4ea;
  --color-camarone: #00601a;
  --color-fireBush: #ea8e2a;
  --color-iceBlue: #e7eaef;
  --color-oxfordBlue: #2F3846;

  /*Border*/
  --main-border-color: var(--color-athensGray-2);
  /*Box shadow*/
  --main-box-shadow: 0 2px 3px 0 rgba(10, 10, 10, 0.06);
}
