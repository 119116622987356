.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 253px;
  background: var(--color-athensGray-3);
  border: 2px dashed var(--color-mischka);
  border-radius: 12px;
  padding-top: var(--gutter-lg);
  gap: 38px;
}

.wrapper:not(.disabled):hover {
  cursor: pointer;
  background: var(--color-athensGray-3);
}

.wrapper.dragEntered {
  border-color: var(--color-sail);
  background: var(--color-selago);
  padding: var(--gutter-lg);
  z-index: 2;
}

.wrapper.dragEntered .placeholder {
  font-size: 18px;
  color: var(--color-bunker);
}

.wrapper.disabled {
  flex-direction: column;
}

.error {
  background: none;
  color: var(--color-bright-red);
}

.errorIcon svg {
  fill: var(--color-bright-red);
}

@media (max-width: 768px) {
  .wrapper {
    height: 100%;
    gap: 12px;
  }
}