.wrapper:global(.ant-typography) {
  font-size: 20px !important;
  color: var(--color-bunker);
  font-weight: 500;
  margin: 0 0 var(--gutter-md) 0;
}

@media (max-width: 768px) {
  .wrapper:global(.ant-typography) {
    display: none;
  }
}