.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
}

.headerCell {
  position: relative;
  width: 100%;
  user-select: none;
}

.fixedRowCell {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .wrapper {
    justify-content: right; 
    width : 100%; 
    padding-right: var(--gutter-sm);
    position: relative; 
  }
  
  .headerCell {
    position: absolute;
    width: 97px;
    height: 32px;
    right: 24px;
    top: 50%; 
    transform: translateY(-50%);
    border: 1px solid var(--color-iceBlue);
    border-radius: 8px;
    padding: 0 var(--gutter-smd);
    align-items: center;
    justify-content: center;
    font-weight: 400;
  }
}
