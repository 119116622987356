.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wrapper :global(.ant-empty) {
  margin: 0;
}

.noData :global(.ant-empty-description) {
  color: var(--color-bunker);
  font-size: 18px;
  font-weight: 500;
}

.noData :global(.ant-empty-image) {
  height: auto;
}

.noDataIcon svg {
  fill: none;
}
