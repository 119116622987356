.stickyHeader {
  position: sticky;
  top: 60px;
  z-index: 3;
  background: var(--color-athensGray-3);
}

.card {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.noData {
  min-height: 400px;
}

.noMinHeight {
  min-height: 50px;
}
