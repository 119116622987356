.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex: 1;
}

.input {
  padding-right: 160px;
}

.wrapper .searchAndReplace {
  width: 100%;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 12px
  }
}