.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: var(--gutter-md);
}

.label {
  margin-right: var(--gutter-md);
  font-weight: 500;
  white-space: nowrap;
  font-size: 18px;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gutter-sm);
    padding: calc(var(--gutter-md) + var(--gutter-md)) var(--gutter-md) 0;
    margin-bottom: var(--gutter-smd);
  }
}