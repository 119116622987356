.wrapper {
  position: relative;
  padding-left: calc(
          var(--transcript-speaker-name-width)
          + var(--transcript-timestamp-width)
          + calc(2 * var(--gutter-lg))
  );
  padding-right: var(--gutter-lg);
  width: 100%;
}

.monologStart {
  padding-top: var(--gutter-lg);
}

.timestamp {
  position: absolute;
  font-size: var(--main-font-size);
  left: var(--gutter-lg);
  top: 0;
  width: var(--transcript-timestamp-width);
  color: var(--color-aluminium);
  white-space: nowrap;
  line-height: 1.57;
}

.speaker {
  font-size: var(--main-font-size);
  font-weight: 600;
  position: absolute;
  width: calc(var(--transcript-speaker-name-width) + calc(2 * var(--gutter-lg)));
  padding-left: calc(2 * var(--gutter-lg));
  left: var(--transcript-timestamp-width);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  
  top: 0;
  line-height: 1.57;
}

.monologStart .timestamp,
.monologStart .speaker {
  top: var(--gutter-lg);
}

.text {
  flex: 1;
  padding-left: var(--gutter-lg);
  font-size: var(--main-font-size);
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .wrapper {
    padding-left: var(--gutter-md);
    padding-right: var(--gutter-md);
    display: flex;
    flex-direction: column;
  }

  .text {
    padding-left: 0;
    width: 100%;
    margin-top: 0;
  }
}