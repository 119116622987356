.wrapper {
  display: flex;
}

.wrapper .button:global(.ant-btn) {
  margin-left: var(--gutter-md);
}

@media (max-width: 768px) {
  .wrapper .button:global(.ant-btn) {
    margin: var(--gutter-smd) 0 0 var(--gutter-smd);
  }
}