.wrapper {
  width: 210px;
  height: 40px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .wrapper {
    width: 194px;
    height: 37px;
    font-size: 14px;
  }
}