.wrapper {
  display: flex;
  width: 100%;
}

.closeButton {
  margin-left: 10px;
  color: var(--color-gray);
}

.closeButton:hover {
  margin-left: 10px;
  color: var(--color-dove-gray);
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: var(--gutter-smd);
  }
}