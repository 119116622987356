.wrapper {
  display: flex;
  align-items: center;
  background: var(--color-white);
  padding-left: var(--gutter-md);
  width: 100%;
  margin-right: var(--gutter-xxl);
}

.wrapper .input :global(.ant-input-clear-icon) {
  color: var(--color-gray);
}

.wrapper .input {
  width: 320px;
}

.wrapper .input :global(.ant-input-clear-icon):hover {
  color: var(--color-dove-gray);
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-end;
    padding-left: 0;
  }
  
  .wrapper .input {
    width: 100%;
  }
}