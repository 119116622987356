.wrapper {
  animation-name: fade-in;
  animation-duration: 300ms;
}

.row {
  display: flex;
}

.headerCell {
  display: flex;
  flex: 1;
  padding-left: var(--gutter-lg);
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: var(--color-blue-bayoux);
}

.cell {
  display: flex;
  align-items: center;
  padding-left: var(--gutter-lg);
  font-weight: 500;
}

.row:last-child .cell {
  border-bottom: none;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .row {
    border-bottom: 1px solid var(--color-iceBlue);
  }
}
