.modalContainer {
  width: 480px !important;
}

.modalContainer :global(.ant-modal-content) {
  padding: var(--gutter-lg);
  border-radius: 16px;
}

.modalContainer :global(.ant-modal-body) {
  padding: 0;
}

.modalContainer :global(.ant-modal-footer) {
  margin: var( --gutter-lg) 0 0 0;
  padding: 0;
  border-top: none;
}

.modalContainer :global(.ant-modal-footer .ant-btn) {
  min-width: 104px;
  height: 47px;
  font-size: 18px;
  border-radius: 8px;
  margin-left: var(--gutter-md) !important;
}

.modalContainer :global(.ant-modal-footer .ant-btn-primary) {
  background: var(--color-guardsman-red);
}

.modalContainer :global(.ant-modal-footer .ant-btn-primary:hover) {
  background: var(--color-bright-red);
}

.modalContainer :global(.ant-modal-footer .ant-btn-default) {
  border: 1px solid var( --color-cadet-blue);
  background: var(  --color-white);
}

.modalContainer :global(.ant-modal-footer .ant-btn-default:hover) {
  border: 1px solid var(  --color-cadet-blue);
  background: var( --color-athensGray-3);
  color: var( --color-bunker);
}

@media (max-width: 768px) {
  .modalContainer :global(.ant-modal-content) {
    margin: 0 var(--gutter-sm);
  }

  .modalContainer :global(.ant-modal-footer .ant-btn) {
    min-width: 83px;
    height: 37px;
    font-size: 14px;
    margin-left: var(--gutter-smd) !important;
  }
}