.wrapper {
  margin-left: var(--gutter-md);
  width: 124px;
  min-height: 40px;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 768px) {
  .wrapper {
    width: 116px;
    min-height: 37px;
    font-size: 14px;
  }
}