.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 8px;
  width: 100%;
  background-color: var(--color-iceBlue);
  border-radius: 0 0 10px 10px;
}

.message {
  color: var(--color-blue-bayoux);
  line-height: 21px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .container {
    justify-content: flex-start;
  }
  .message {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 527px) {
  .container {
    justify-content: flex-start;
    padding: 24px 8px;
  }
}