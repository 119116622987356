.wrapper {
  position: relative;
  padding: 0 var(--gutter-xxl) 0 var(--gutter-lg);
  margin-bottom: var(--gutter-md);
}

.closeButton {
  position: absolute;
  right: var(--gutter-lg);
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 var(--gutter-md);
    
  }

  .closeButton {
    top: var(--gutter-sm);
    right: var(--gutter-smd);
  }
}