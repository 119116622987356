.wrapper {
  position: sticky;
  top: var(--main-header-height);
  width: 100%;
  max-width: var(--main-width);
  height: calc(var(--audio-player-section-height) + var(--gutter-xl));
  padding-top: var(--gutter-xl);
  background: var(--color-athensGray);
  margin: auto;
  z-index: 4;
}

.cardWrapper {
  width: 100%;
  height: var(--audio-player-section-height);
  min-height: auto;
  margin: 0;
  position: relative;
  padding-left: 56px
}

.header {
  border-bottom: none;
  padding: var(--gutter-lg) var(--gutter-lg) 0 var(--gutter-lg);
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-dodgerBlue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
}

.content {
  padding: var(--gutter-md) var(--gutter-lg);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.headerContent {
  display: flex;
  flex-direction: row;
  gap: var(--gutter-md);
}

.backButton {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;
}

@media (max-width: 768px) {
  .wrapper {
    padding-top: var(--gutter-md);
  }
}