.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.dropdown {
  max-width: 200px;
}

.wrapper .button {
  padding: 0px;
}

.wrapper .button:not(:disabled):hover,
.wrapper .button:not(:disabled):focus {
  background: var(--color-sail);
}

.dropdown.dropdown :global(.ant-dropdown-menu-item) {
  padding: var(--gutter-sm);
}

.userProfile {
  margin-left: -8px;
  font-weight: 500;
}

.dropdown :global(.ant-dropdown-menu-item) .userProfile :global(.ant-typography) {
  color: var(--color-blue-bayoux);
}

.logoutButton {
  margin: 0;
}

.helpAndSupport {
  font-weight: 500;
}