.wrapper {
  padding-top: 70px;
  margin: 0;
}

.wrapper.tabSelected {
  padding-top: 120px;
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: var(--gutter-md);
  }
}