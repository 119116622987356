.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.timestamp {
  font-size: var(--main-font-size);
  color: var(--color-aluminium);
  white-space: nowrap;
  line-height: 1.57;
}

.speaker {
  padding-right: var(--gutter-md);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.57;
}