.wrapper {
  width: 480px !important;
}

.wrapper :global(.ant-modal-content) {
  padding: var(--gutter-lg);
  border-radius: 16px;
}

.wrapper :global(.ant-modal-body) {
  padding: 0;
}

.wrapper :global(.ant-modal-footer) {
  margin: var( --gutter-lg) 0 0 0;
  padding: 0;
  border-top: none;
}

.wrapper :global(.ant-modal-footer .ant-btn) {
  min-width: 104px;
  height: 47px;
  font-size: 18px;
  border-radius: 8px;
  margin-left: var(--gutter-md) !important;
}

.wrapper .input {
  height: 48px;
  font-size: 16px;
  margin-top: var(--gutter-md);
  padding: var(--gutter-md);
}

@media (max-width: 768px) {
  .wrapper :global(.ant-modal-content) {
    margin: 0 var(--gutter-sm);
  }

  .wrapper :global(.ant-modal-footer .ant-btn) {
    min-width: 51px;
    height: 37px;
    font-size: 14px;
    margin-left: var(--gutter-smd) !important;
  }

  .wrapper .input {
    height: 42px;
    font-size: 14px;
  }
}