.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 8px;
}

.wrapper.disabled .description {
  color: var(--color-cadet-blue);
  transition: none;
}

.wrapper.processing {
  flex-direction: column;
}

.noData {
  margin-inline: 0;
}

.noData :global(.ant-empty-image) {
  margin-left: auto;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
  .noData :global(.ant-empty-image) {
    width: 75px;
    height: 75px;
    margin-left: 12px;
  }
}
