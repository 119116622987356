.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  font-weight: 500;
  margin-right: var(--gutter-md);
  white-space: nowrap;
  font-size: 16px;
}

.button {
  margin-left: var(--gutter-md);
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: start;
    gap: var(--gutter-smd);
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: flex-center;
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
