.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 253px;
}

.submit {
  margin-left: auto;
}

@media (max-width: 527px) {
  .wrapper {
    height: 269px;
  }
}