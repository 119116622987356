.wrapper .button {
  padding: 0px;
}

.wrapper .button:not(:disabled):hover,
.wrapper .button:not(:disabled):focus {
  background: var(--color-sail);
}

@media (max-width: 768px) {
  .wrapper .button {
    margin-left: 8px;
  }
}